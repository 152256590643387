import * as React from "react"
import { Link } from "gatsby"
import { useEffect } from "react";

import Layout from "../components/layout"
import Seo from "../components/seo"

import ImageHero from "../images/hero-il-6.png"
import ImageVideoPlaceholder from "../images/video-placeholder.png"
import ImageLineSmall from "../images/line-small.svg"
import ImageLineLong from "../images/line-long.svg"

import ImageBgImplicatedPathogenesis from "../images/il-6-bg-implicated-pathogenesis.png"
import ImageBgImpactsPmrOutcomes from "../images/il-6-bg-impacts-pmr-outcomes.png"
import ImageBgBursitisShoulder from "../images/il-6-shoulder-muscle-pain-and-stiffness.png"
import ImageBgProimflammatory from "../images/il-6-bg-proinflammatory-mobile.png"
import ImageBgHigherConcentrations from "../images/il-6-bg-higher-concentrations.png"

import ImageChartIl6Levels from "../images/il-6-chart-il-6-and-pmr.png"
import ImageChartSerumIl6Levels from "../images/il-6-chart-serum-il-6-levels-correlate-with-esr-and-crp-levels.png"
import ImageChartSolubleIl6Receptor from "../images/il-6-chart-sil-6r-levels-correlate-with-number-of-pmr-relapses.png"

import ImageChartDesktopProinflammatory from "../images/icons-desktop-proinflammatory.png"
import ImageChartMobileProinflammatory from "../images/icons-mobile-proinflammatory.png"
import ImageChartEndlessCycle from "../images/il-6-elevated-IL-6-levels-may-be-associated-with-symptoms.png"

import ImageIconCalloutArrow from "../images/icon-callout-brown-arrow.svg"
//import ReactPlayerLoader from '@brightcove/react-player-loader';

const Il6AndPmr = () => {

  // const vp = `<video-js data-account="3845398857001" data-player="default" data-embed="default" controls="" data-video-id="6312867406112" data-playlist-id="" data-application-id="" width="300" height="150" class="vjs-fluid"></video-js>`;

  // const videoSuccess =(success)=>{
  //   let el = success.ref.el_;
  //   el.setAttribute("width", 300);
  //   el.setAttribute("height", 150);
  //   el.classList.add('vjs-fluid');
  // }
  useEffect(() => {
    // Load Vimeo player script
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout pageid="il-6-and-pmr">
      <Seo title="IL-6 and PMR | PMR and IL-6 HCP Website" description="Learn more about the role cytokines play in inflammatory responses, and the relationship between IL-6 and PMR."  ogDesc="Learn more about the role cytokines play in inflammatory responses, and the relationship between IL-6 and PMR." lang="en-US"/>
      <div className="content-container">
        <div id="hero-image-container" className="mb-8 md_mb-8">
          <h1>IL-6 AND PMR</h1>
          <img src={ImageHero} alt="cytokines" id="hero-il-6-pmr" />
        </div>

        <div id="il-6-understanding-pmr-container" className="mb-6 md_mb-6 lg_mb-2">
          <div className="content-container">
            <h2 className="section-headline">Understanding PMR Pathophysiology: An Expert's Perspective</h2>
            <p><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
            <p className="section-subhead mb-4 md_mb-4">Hear Dr Leonard Calabrese Discuss the Pathophysiology of PMR and the Role of Elevated IL-6 in PMR:</p>

            <div id="il-6-video-container" className="mb-4 md_mb-4">
                <div style={{ paddingTop: "56.21%", position: "relative", margin: 0, border: "1px solid #f4f4f4", overflow: 'hidden', width: 'calc(100% - 1px)' }}>
                  <iframe
                    src="https://player.vimeo.com/video/949571707?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", margin: 0, padding: 0, backgroundColor: 'transparent' }}
                    title="The Pathophysiology of PMR and the Role of Elevated IL-6 in PMR"
                  ></iframe>
                </div>
            </div>

            <p className="mb-1 md_mb-1">Listen to an expert's perspective on the following questions:</p>
            <ul>
              <li>What are the patterns of inflammation commonly seen in patients with PMR?</li>
              <li>What immune pathways have been linked to PMR disease activity?</li>
              <li>What makes the pathophysiology of PMR different from other rheumatic diseases?</li>
              <li>What evidence exists for the role of IL-6 in PMR pathophysiology?</li>
              <li>How does IL-6 contribute to disease progression?</li>
            </ul>
          </div>
        </div>

        <div id="il-6-proinflammatory-container" className="mb-6 md_mb-4 lg_mb-6">
          <div className="content-container">
            <h2 className="section-headline">Proinflammatory cytokines play important roles in the pathogenesis of PMR<sup>1</sup></h2>
            <p><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2" style={{ width: '99%' }} /></p>
            <p className="mb-2 md_mb-2">Key hallmarks associated with PMR, such as painful inflammation, can be attributed, in part, to certain immune cells and cytokines, such as IL-6, IL-1, IL-17, IL-10, and TNF&alpha;.<sup>1-5</sup></p>
            <p className="bg-color-gray-light text-center show-for-medium md_mb-3"><img src={ImageChartDesktopProinflammatory} alt="IL-6, IL-1, IL-10, IL-17, TNFa" style={{ width: '90%', padding: '2% 0' }} /></p>
            <p className="bg-color-gray-light text-center show-for-small-only mb-2"><img src={ImageChartMobileProinflammatory} alt="IL-6, IL-1, IL-10, IL-17, TNFa" style={{ width: '70%', padding: '15% 0', minWidth: '250px' }} /></p>
          </div>
          <img src={ImageBgProimflammatory} alt="" id="il-6-bg-proimflammatory-mobile"  />
        </div>

        <div id="il-6-implicated-impacts-container" className="mb-6 md_mb-6">
          <div className="row" style={{ maxWidth: 'unset' }}>
            <div className="columns small-12 medium-12 large-7 mb-6" style={{ paddingLeft: '0', paddingRight: '0' }}>
              <div className="il-6-iic-content left">
                <h2 className="section-headline">IL-6 is implicated in the pathogenesis of PMR</h2>
                <p><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
                <p>Under normal physiologic conditions, IL-6 performs many functions, including vital proinflammatory functions in response to infection or injury.<sup>6</sup> However, <strong className="color-orange-rust">persistently elevated IL-6 levels drive inflammation</strong>, which can help promote the pathologic conditions observed in autoimmune and inflammatory conditions such as PMR.<sup>1,7,8</sup></p>
                <img src={ImageBgImplicatedPathogenesis} alt="" id="il-6-image-bg-implicated-pathogenesis" className="show-for-large" />
              </div>
            </div>
            <div className="columns small-12 medium-12 large-5 mb-6" style={{ paddingLeft: '0', paddingRight: '0' }}>
              <div className="il-6-iic-content right">
                <img src={ImageBgImpactsPmrOutcomes} alt="" id="il-6-image-bg-impacts-pmr-outcomes" />
                <h2 className="section-headline">IL-6 impacts PMR symptoms</h2>
                <p><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
                <ul>
                  <li>IL-6 levels correlate with PMR disease activity<sup>3</sup></li>
                  <li>Elevated serum soluble IL-6 concentrations were identified as a potential prognostic marker for PMR relapses<sup>3,9</sup></li>
                  <li>Synovitis is observed in PMR as a type of inflammation accompanied by increased level of IL-6 in the synovial fluid<sup>7,10,11</sup></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id="il-6-bursitis-shoulder-container"><img src={ImageBgBursitisShoulder} alt="shoulder muscle pain and stiffness" id="il-6-image-bg-bursitis-shoulder" /></div>

        <div id="il-6-endless-cycle-container" className="mt-2 lg_mt-15 mb-6 md_mb-6">
          <div className="content-container">
            <h2 className="section-headline">A cycle of inflammation</h2>
            <p><img src={ImageLineSmall} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2" id="id-6-endless-line-small" /></p>
            <p className="mb-2 md_mb-2">A study of patients with PMR demonstrated that muscle pain and stiffness concurrently developed when IL-6 concentrations were increased.<sup>1</sup></p>
            <p className="section-subhead mb-2 md_mb-2">In PMR, IL-6 is a major driver of acute-phase response and systemic inflammation and<sup>1,8</sup>:</p>
            <ul className="mb-2 md_mb-2">
              <li>Stimulates increases in C-reactive protein and erythrocyte sedimentation rate<sup>4</sup></li>
              <li>Plays an important role in pain perception and peripherally induced pain, and the degree of IL-6 elevation correlates with the magnitude of pain<sup>12</sup></li>
            </ul>
            <p className="mb-3 md_mb-3">The increase of IL-6 is associated with fatigue, impaired sleep, and mood disorders, particularly anxiety and depression.<sup>13,14</sup></p>
          </div>
          <div id="il-6-endless-cycle-chart"><img src={ImageChartEndlessCycle} alt="elevated IL-6 levels may be associated with symptoms" /></div>
        </div>



        <div id="il-6-higher-concentrations-container" className="mb-10 md_mb-10">
          <h2 className="section-headline">Elevated concentrations of plasma IL-6 are a characteristic feature in patients with PMR vs healthy controls<sup>3</sup></h2>
          <p className="show-for-small-only"><img src={ImageLineSmall} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
          <p className="show-for-medium"><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-3 md_mt-3"/></p>
          <div className="text-center">
            <div className="section-subhead mb-3 md_mb-3">IL-6 levels correlate with PMR disease activity<sup>3</sup></div>
            <div className="il-6-hc-chart-container mb-4 md_mb-4 pb-2">
              <p className="mt-2 md_mt-2 mb-2 md_mb-3"><img src={ImageChartIl6Levels} alt="IL-6 and PMR" /></p>
              <p className="section-study-design-body" style={{ width: '90%', margin: '0 auto' }}>
                Correlation of interleukin-6 production and disease activity in polymyalgia rheumatica and giant cell arteritis. Roche NE, Fulbright JW, Wagner AD, et al. Copyright © 1993, American College of Rheumatology. Reproduced with permission of John Wiley & Sons Ltd.
              </p>
            </div>

            <div className="mb-4 md_mb-6">
              <p className="section-study-design-headline text-left mb-1 md_mb-1"><strong>Study Design<sup>3</sup></strong></p>
              <p className="section-study-design-body text-left mb-1 md_mb-1">This study explored the role of proinflammatory cytokines in giant cell arteritis (GCA) and polymyalgia rheumatica (PMR), two clinically related syndromes characterized by an intense acute-phase reaction. In particular, to determine plasma concentrations of interleukin-6 (IL-6) and tumor necrosis factor &alpha; (TNF&alpha;) and to correlate changes in plasma IL-6 levels with clinical symptoms during corticosteroid therapy. Thirteen patients with untreated PMR and 19 patients with untreated, biopsy-proven GCA were entered in the study with twenty normal subjects who had no evidence of an inflammatory disease as controls. IL-6 and TNF&alpha; concentrations were determined in plasma samples from patients with un­treated PMR or GCA, and plasma IL-6 levels were monitored in patients receiving long-term therapy (14 months) with corticosteroids. To identify IL-6&ndash;producing cells, the polymerase chain reaction was used to detect IL-6 messenger RNA. In vitro production of IL-6 and IL-2 by peripheral blood mononuclear cells (PBMC) from treated and untreated patients was quan­tified using IL-6– and IL-2–specific bioassay systems.</p>
              <p className="section-study-design-body text-left mb-1 md_mb-1">Roche NE, Fulbright JW, Wagner AD, et al. Correlation of interleukin-6 production and disease activity in polymyalgia rheumatica and giant cell arteritis. <em>Arthritis & Rheumatism</em>. 1993;36(9):1286-1294. Reproduced by permission of Oxford University Press on behalf of the British Society for Rheumatology.</p>
            </div>


          <div className="section-subhead mb-3 md_mb-3">Serum IL-6 levels correlate with ESR and CRP levels<sup>15</sup></div>
          <div className="il-6-hc-chart-container mb-4 md_mb-4">
            <p className="mt-2 md_mt-2 mb-3 md_mb-3"><img src={ImageChartSerumIl6Levels} alt="" /></p>
            <p className="section-study-design-body" style={{ width: '90%', margin: '0 auto' }}>
              Van der Geest K, Abdulahad WH, Rutgers A, et al. Serum markers associated with disease activity in giant cell arteritis and polymyalgia rheumatica. <em>Rheumatology</em>. 2015;54:1397-1402, by permission of Oxford University Press on behalf of the British Society for Rheumatology.
            </p>
          </div>
          <div className=" mb-4 md_mb-8">
            <p className="section-study-design-headline text-left mb-1 md_mb-1"><strong>Study Design<sup>15</sup></strong></p>
            <p className="section-study-design-body text-left mb-1 md_mb-1">This prospective study compared multiple serum markers for their ability to detect active disease in patients with GCA and/or PMR. It analyzed twenty-six markers related to immune cells that may be involved in GCA and PMR were determined by ELISA and multiplex assay in the serum of 24 newly diagnosed, untreated GCA/PMR patients, 14 corticosteroid (CS)-treated GCA/PMR patients in remission and 13 healthy controls. Receiver operating characteristic analysis with area under the curve and Spearman’s correlation coefficients were performed.</p>
            <p className="section-study-design-body text-left mb-1 md_mb-1">Van der Geest K, Abdulahad WH, Rutgers A, et al. Serum markers associated with disease activity in giant cell arteritis and polymyalgia rheumatica. <em>Rheumatology</em>. 2015;54:1397-1402, https://doi.org/10.1093/rheumatology/keu526. Reproduced by permission of Oxford University Press on behalf of the British Society for Rheumatology.</p>
          </div>


            <div className="section-subhead mb-3 md_mb-3">Soluble IL-6 receptor (sIL-6R) levels correlate with the number of PMR relapses<sup>15</sup></div>
            <div className="il-6-hc-chart-container mb-4 md_mb-4 pb-2">
              <p className="mt-2 md_mt-2 mb-2 md_mb-3"><img src={ImageChartSolubleIl6Receptor} alt="sIL-6R levels correlate with number of PMR relapses" /></p>
              <p className="section-study-design-body" style={{ width: '90%', margin: '0 auto' }}>
                Serum interleukin-6 receptor in polymyalgia rheumatica: a potential marker of relapse/recurrence risk. Pulsatelli L, Boiardi L, Pignotti E, et al. Copyright © 2008, American College of Rheumatology. Reproduced with permission of John Wiley & Sons Ltd.
              </p>
            </div>
            <div className=" mb-4 md_mb-4">
              <p className="section-study-design-headline text-left mb-1 md_mb-1"><strong>Study Design<sup>15</sup></strong></p>
              <p className="section-study-design-body text-left mb-1 md_mb-1">This study investigated the modulation of systemic levels of soluble interleukin-6 receptor (sIL-6R) and soluble gp130 (sgp130) in untreated and treated polymyalgia rheumatica (PMR) patients during a followup period of at least 24 months in order to evaluate the relationship of these molecules with clinical outcome and their feasibility to provide a prognostic tool in clinical practice. It analyzed sIL-6R and sgp130 serum levels in 93 PMR patients, and 46 age-matched normal controls, at disease onset and at 1, 3, 6, 12, and 24 months of followup during corticosteroid therapy by enzyme-linked immunosorbent assay.</p>
              <p className="section-study-design-body text-left mb-1 md_mb-1">Pulsatelli L, Boiardi L, Pignotti E, et al. Serum interleukin-6 receptor in polymyalgia rheumatica: a potential marker of relapse/recurrence risk. <em>Arthritis Rheum</em>. 2008;59(8):1147-1154, DOI 10.1002/art.23924, https://doi.org/10.1093/rheumatology/keu526. Reproduced by permission of Oxford University Press on behalf of the British Society for Rheumatology.</p>
            </div>

            <img src={ImageBgHigherConcentrations} alt="" id="il-6-image-bg-higher-concentrations" />
          </div>
        </div>


        <Link to="/standard-of-care/" className="gen-brown-callout">
          <div className="callout-brown-generic right" style={{ width: '85%' }}>
            <div className="row">
              <div className="columns small-12 medium-8 text-center">Explore the standard of care <span className="nowrap-desktop">for PMR</span></div>
              <div className="columns small-12 medium-4 text-center nowrap align-self-middle"><img src={ImageIconCalloutArrow} alt="" /></div>
            </div>
          </div>
        </Link>

        <div id="references" class="il-6">
          <div id="references-content">
            <p>
              <strong>References: 1.</strong> Gonzalez-Gay MA, Matteson EL, Castañeda S. Polymyalgia rheumatica. <em>Lancet.</em> 2017; 390:1700-1712.&nbsp;
              <strong>2.</strong> Toussirot E, Régent A, Devauchelle-Pensec V, et al. Interleukin-6: a promising target for the treatment of polymyalgia rheumatica or giant cell arteritis? <em>RMD Open.</em> 2016;2:e000305. doi:10.1136/rmdopen-2016-000305.&nbsp;
              <strong>3.</strong> Roche NE, Fulbright JW, Wagner AD, et al. Correlation of interleukin-6 production and disease activity in polymyalgia rheumatica and giant cell arteritis. <em>Arthritis & Rheumatism.</em> 1993;36(9):1286-1294.&nbsp;
              <strong>4.</strong> Guggino G, Ferrante A, Macaluso F, et al. Pathogenesis of polymyalgia rheumatica. <em>Reumatismo.</em> 2018;70(1):10-17.&nbsp;
              <strong>5.</strong> Martinez-Taboada VM, Alvarez L, RuizSoto M, et al. Giant cell arteritis and polymyalgia rheumatica: role of cytokines in the pathogenesis and implications for treatment. <em>Cytokine.</em> 2008;44(2):207-220.&nbsp;
              <strong>6.</strong> Tanaka T, Kishimoto T. Targeting interleukin-6: all the way to treat autoimmune and inflammatory diseases. <em>Int J Biol.</em> 2012;8(9):1227-1236.&nbsp;
              <strong>7.</strong> Jiemy WF, Zhang A, Boots AMH, et al. Expression of interleukin-6 in synovial tissue of patients with polymyalgia rheumatica. <em>Ann Rheum Dis.</em> 2022;0:1-2. doi:10.1136/annrheumdis-2022-222873.&nbsp;
              <strong>8.</strong> Salvarani C, Cantini F, Niccoli L, et al. Acute-phase reactants and the risk of relapse/recurrence in polymyalgia rheumatica: a prospective followup study. <em>Arthritis & Rheumatism.</em> 2005;53(1):33-38.&nbsp;
              <strong>9.</strong> Mori S, Koga Y. Glucocorticoid-resistant polymyalgia rheumatica: pretreatment characteristics and tocilizumab therapy. <em>Clin Rheumatol.</em> 2016;35:1367-1375.&nbsp;
              <strong>10.</strong> Zen-nyoji A, Shimizu H, Ohtani K, et al. Increased RAHA titer and interleukin-6 levels in the synovial fluid in a patient with polymyalgia rheumatica. <em>Intern Med.</em> 1993;32(6):484-486.&nbsp;
              <strong>11.</strong> Lundberg I, Sharma A, Turesson C, Mohammed AJ. An update on polymyalgia rheumatica. <em>J Intern Med.</em> 2022;292:717-732.&nbsp;
              <strong>12.</strong> Sebba A. Pain: a review of interleukin-6 and its roles in the pain of rheumatoid arthritis. <em>Open Access Rheumatol.</em> 2021;13:31-43.&nbsp;
              <strong>13.</strong> Grygiel-Górniak B, Puszczewicz M. Fatigue and interleukin-6—a multi-faceted relationship. <em>Reumatologia.</em> 2015;53(4):207-212&nbsp;
              <strong>14.</strong> Acharya S, Musa R. Polymyalgia Rheumatica. <em>NCBI Bookshelf.</em> StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2022.&nbsp;
              <strong>15.</strong> Van der Geest K, Abdulahad WH, Rutgers A, et al. Serum markers associated with disease activity in giant cell arteritis and polymyalgia rheumatica. <em>Rheumatology.</em> 2015;54:1397-1402.&nbsp;
              <strong>16.</strong> Pulsatelli L, Boiardi L, Pignotti E, et al. Serum interleukin-6 receptor in polymyalgia rheumatica: a potential marker of relapse/recurrence risk. <em>Arthritis Rheum.</em> 2008;59(8):1147-1154.
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Il6AndPmr
